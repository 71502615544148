import { faFacebookF, faInstagram, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faMapMarker, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Footer = () => {
  return (
    <footer className='footer mt-auto'>
      <div className='row my-5'></div>
      <div className='row mt-5'>
        <div className='col-md-3'>
          <h2>Jon Littler</h2>
        </div>

        <div className='col-md-4'>
          <h5>About</h5>
          <p className='text-secondary'>
            Enthusiastic, outgoing IT professional
            with over 30 years of experience in the finance industry.
            Innovative, analytic and result-driven individual with
            extensive experience on global large-scale software development
            projects.
          </p>
        </div>

        <div className='col-md-3'>
          <h5>Contact</h5>
          <ul className='list-unstyled text-secondary'>
            <li className='mb-1'><FontAwesomeIcon icon={faEnvelope} size='lg' /> info@jonlittler.com</li>
            <li className='mb-1'><FontAwesomeIcon icon={faPhone} size='lg' /> +1 917-400-3699</li>
            <li className='mb-1'><FontAwesomeIcon icon={faMapMarker} size='lg' /> New York, U.S.A</li>
          </ul>
        </div>

        <div className='col-md-2'>
          <h5>Follow</h5>
          <ul className='list-inline footer-links'>
            <li className='list-inline-item'>
              <a href='https://www.facebook.com/jon.littler/'>
                <FontAwesomeIcon icon={faFacebookF} size='lg' />
              </a>
            </li>
            <li className='list-inline-item'>
              <a href='https://twitter.com/jonlittler'>
                <FontAwesomeIcon icon={faXTwitter} size='lg' />
              </a>
            </li>
            <li className='list-inline-item'>
              <a href='https://www.instagram.com/jonlittler'>
                <FontAwesomeIcon icon={faInstagram} size='lg' />
              </a>
            </li>
            <li className='list-inline-item'>
              <a href='https://www.linkedin.com/in/jonlittler'>
                <FontAwesomeIcon icon={faLinkedin} size='lg' />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-md-6'>
          <p className='text-secondary'>© 2024 Jon Littler. All rights reserved.</p>
        </div>
        <div className='col-md-6 text-end'>
          <ul className='list-inline footer-links'>
            <li className='list-inline-item'>
              <p className='text-secondary'>Privacy Policy</p>
            </li>
            <li className='list-inline-item'>
              <p className='text-secondary'>Terms of Service</p>
            </li>
            <li className='list-inline-item'>
              <p className='text-secondary'>Sitemap</p>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
