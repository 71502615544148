import React, { Fragment } from 'react'
import ActivityCard from './ActivityCard'

const ActivityList = ({ activity_set }) => {
  return (
    <Fragment>
      {activity_set.map((activity) => <ActivityCard key={activity.slug} activity={activity} />)}
    </Fragment>
  )
}

export default ActivityList
