import React from 'react'

const Modal = ({ activity }) => {
  return (
    <div className='modal fade' id={`modal${activity.slug}`} tabIndex='-1'>
      <div className='modal-dialog modal-lg modal-dialog-scrollable'>
        <div className='modal-content bg-dark'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='exampleModalLabel'>{activity.title}</h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
          </div>
          <div className='modal-body'>
            {activity.image && <img src={activity.image.image_url} alt='' className='w-100 rounded' />}
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
