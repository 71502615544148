import React from 'react'
import { Link } from 'react-router-dom'
import Audio from './Audio'
import Document from './Document'
import Modal from './Modal'
import Video from './Video'

const ActivityCard = ({ activity }) => {
  return (
    <div className='card container bg-dark text-light mt-5' >
      <div className='card-body'>
        <div className='row g-5 py-3'>

          <div className='col-md-5'>
            <Link type='button' data-bs-toggle='modal' data-bs-target={`#modal${activity.slug}`}>
              {activity.image && <img src={activity.image.image_url} alt='' className='w-100 rounded' />}
            </Link>
          </div>

          <div className='col-md-7'>
            <h1 className='card-title'>{activity.title}</h1>
            <h6 className='card-subtitle text-body-secondary mb-3'>{activity.subtitle}</h6>
            <p className='card-text'>{activity.body}</p>
            {activity.file && activity.file.mime_type === 'application/pdf' && <Document file={activity.file} />}
            {activity.file && activity.file.mime_type === 'audio/mpeg' && <Audio file={activity.file} />}
            {activity.file && activity.file.mime_type === 'video/mp4' && <Video file={activity.file} />}
          </div>
        </div>
      </div>
      <Modal activity={activity} />
    </div>
  )
}

export default ActivityCard
