import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import AboutPage from './pages/AboutPage';
import BasicPage from './pages/BasicPage';
import HomePage from './pages/HomePage';

const App = () => {
  return (
    <Router>
      <div className='content'>
        <div className='container d-flex flex-column h-100 text-white'>
          <Header />
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/jimweb/:type' element={<BasicPage />} />
            <Route path='*' element={<HomePage />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App
