import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ActivityList from '../components/ActivityList'
import Hero from '../components/Hero'
// import { ENDPOINT } from '../config'

const BasicPage = () => {

  console.log('REACT_APP_ENDPOINT', process.env.REACT_APP_ENDPOINT)

  const { type } = useParams();
  console.log(type)

  const [menuitem, setMenuitem] = useState()

  useEffect(() => {
    const get_menuitem = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/menuitem-activity/${type}`)
        const data = await res.json()
        setMenuitem(data)
      } catch (error) {
        console.log("Error fetching:", error)
      }

    }
    get_menuitem()
  }, [type])

  if (!menuitem) return (
    <div className='d-flex flex-column align-items-center mt-5'>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <p className='mt-3'>Loading...</p>
    </div>
  )

  return (
    <div>
      <Hero menuitem={menuitem} />
      <ActivityList activity_set={menuitem.activity_set} />
    </div>
  )
}

export default BasicPage
