import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

const Document = ({ file }) => {
  const filename = file.file_url ? file.file_url.substring(file.file_url.lastIndexOf('/') + 1) : null
  return (
    <button className='btn btn-primary mt-3 w-50'>
      <FontAwesomeIcon icon={faDownload} className='me-3' />
      <Link to={file.file_url}
        target='_blank' download={filename}
        className='card-link text-white'>Download</Link>
    </button>
  )
}

export default Document
