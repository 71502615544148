import React from 'react'
import { Link, NavLink } from 'react-router-dom'
// import { ENDPOINT } from '../config'

const Header = () => {
  const user = false
  return (
    <nav className='navbar navbar-expand-lg bg-dark navbar-dark bg-opacity-75 fixed-top mt-3'>
      <div className='container-fluid'>
        <Link className='navbar-brand' to='/'>
          <img src={process.env.PUBLIC_URL + '/jonlittler-logo.jpg'} alt='' width='30' height='30' className='rounded' />
        </Link>
        <Link className='navbar-brand' to='/'>Jon Littler</Link>
        <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarNav'>
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNav'>
          <ul className='navbar-nav w-100'>

            <li className='nav-item'>
              <NavLink className='nav-link' to='/jimweb/je-littler-online-by-jon-littler'>JeL Online</NavLink>
            </li>

            <li className='nav-item dropdown'>
              <Link className='nav-link dropdown-toggle' to='#' role='button' data-bs-toggle='dropdown'>Sea Cadets</Link>
              <ul className='dropdown-menu dropdown-menu-dark'>
                <li><NavLink className='dropdown-item' to='/jimweb/sea-cadets-joining-up-1952'>Joining Up 1952</NavLink></li>
                <li><NavLink className='dropdown-item' to='/jimweb/sea-cadets-spithead-1953'>Spithead 1953</NavLink></li>
                <li><NavLink className='dropdown-item' to='/jimweb/sea-cadets-whale-island-1954'>Whale Island 1954</NavLink></li>
              </ul>
            </li>

            <li className='nav-item dropdown'>
              <Link className='nav-link dropdown-toggle' to='#' role='button' data-bs-toggle='dropdown'>Family History</Link>
              <ul className='dropdown-menu dropdown-menu-dark'>
                <li><NavLink className='dropdown-item' to='/jimweb/family-history-littler-family-tree'>Littler Family Tree</NavLink></li>
                <li><NavLink className='dropdown-item' to='/jimweb/family-history-littler-family-name'>Littler Family Name</NavLink></li>
                <li><hr className='dropdown-divider' /></li>
                <li><NavLink className='dropdown-item' to='/jimweb/family-history-download-littler-family-tree'>Download Littler Family Tree</NavLink></li>
                <li><NavLink className='dropdown-item' to='/jimweb/family-history-download-this-is-your-life'>Download This is Your Life</NavLink></li>
              </ul>
            </li>

            <li className='nav-item dropdown'>
              <Link className='nav-link dropdown-toggle' to='#' role='button' data-bs-toggle='dropdown'>Guitar</Link>
              <ul className='dropdown-menu dropdown-menu-dark'>
                <li><NavLink className='dropdown-item' to='/jimweb/guitar-jim'>Jim</NavLink></li>
                <li><NavLink className='dropdown-item' to='/jimweb/guitar-dennis'>Dennis</NavLink></li>
                <li><NavLink className='dropdown-item' to='/jimweb/guitar-photos'>Photos</NavLink></li>
              </ul>
            </li>

            <li className='nav-item dropdown'>
              <Link className='nav-link dropdown-toggle' to='#' role='button' data-bs-toggle='dropdown'>Jazz</Link>
              <ul className='dropdown-menu dropdown-menu-dark'>
                <li><NavLink className='dropdown-item' to='/jimweb/jazz-my-interest-in-jazz'>Interest</NavLink></li>
                <li><NavLink className='dropdown-item' to='/jimweb/jazz-listen-while-you-read'>Listen</NavLink></li>
                <li><NavLink className='dropdown-item' to='/jimweb/jazz-watch-our-improvisations'>Watch</NavLink></li>
              </ul>
            </li>

            <li className='nav-item ms-auto'>
              {user ?
                <Link className='nav-link'>Logout</Link> :
                <a className='nav-link' href={`${process.env.REACT_APP_ENDPOINT}/admin`}>Login</a>}
            </li>
            <li className='nav-item'>
              <a className='nav-link' target='_blank' rel='noreferrer' href={`${process.env.REACT_APP_ENDPOINT}/api`}>API</a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' target='_blank' rel='noreferrer' href='https://www.kudoboard.com/boards/QXCEpjEk/jimlittler'>Kudoboard</a>
            </li>

            {/* <li className='nav-item'>
              <NavLink className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} to='/jimweb/je-littler-online-by-jon-littler'>Home</NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} to='/about'>About</NavLink>
            </li> */}

            <li className='nav-item'>
              <a href={process.env.PUBLIC_URL + '/jimweb3/Index.htm'} className='nav-link'>JeL Online 2006</a>
            </li>
          </ul>
        </div>
      </div>
    </nav >
  )
}

export default Header
