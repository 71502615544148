import React from 'react'

const Hero = ({ menuitem }) => {
  return (
    <div className='bg-secondary bg-opacity-50 text-light mt-5 p-3 rounded'>
      <div className='display-5'>{menuitem.category}</div>
      <h4 className='mt-2 text-warning'>{menuitem.subcategory}</h4>
    </div>
  )
}

export default Hero
